.header {
  background-color: #000000;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  font-family: 'Alumni Sans', sans-serif;
  position: relative;
}

.logo {
  font-weight: bold;
  margin-right: 20px;
  padding-left: 50px;
  font-size: 24px;
}

.navbar {
  display: flex;
  gap: 30px;
}

.nav-button {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: #ff0000;
}

.newnav-button {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  position: relative;
  transition: color 0.3s ease;
}

.hovered-rectangle {
  position: absolute;
  top: 80px; 
  left: 0;
  width: 100%;
  max-height: 187px; 
  background-color: #000000;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.header:hover .hovered-rectangle {
  height: 150px; 
}

/* Dropdown styles */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000000;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.nav-button:hover .dropdown-content {
  display: flex;
  flex-direction: column; 
  align-items: flex-start; 
  gap: 10px; 
  top: 100%; 
  left: 0;
}

.dropdown-content a {
  display: inline-block;
  padding: 8px 16px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-content a:hover {
  background-color: #ff0000;
  color: #fff;
}

.action-button {
  background-color: #ff0000;
  margin-right: 40px;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Alumni Sans', sans-serif;
  font-size: 18px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.action-button:hover {
  background-color: #fff;
  color: #ff0000;
}

