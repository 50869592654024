body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app.video-1 {
  background: #000; 
  position: relative;
  overflow: hidden;
}

.app.video-2 {
  background: #000; 
  position: relative;
  overflow: hidden;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.header .hovered-rectangle {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #000000;
  overflow: hidden;
  transition: height 0.3s ease;
}

.header:hover .hovered-rectangle {
  height: 200px;
}

.wrapper {
  display: flex;
}

aside {
  width: 200px;
  background-color: #f4f4f4;
  padding: 1rem;
}

main {
  flex: 1;
  padding: 1rem;
}

footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.marquee-container {
  position: fixed;
  bottom: 60px; 
  left: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2; 
}

